@import '~normalize.css/normalize.css';
@import '~leaflet/dist/leaflet.css';
@import './styles/core';
@import './styles/table';

html,
body {
  height: 100%;
  @include mat-typography-level-to-styles($app-typography, body-1);
}

.container {
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 10px 0 10px;

  &:first-child {
    padding: 10px 10px 0 10px;
  }

  &:last-child {
    padding: 0 10px 10px 10px;
  }
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 10px 0 10px;

  &:first-child {
    padding: 10px 10px 0 10px;
  }

  &:last-child {
    padding: 0 10px 10px 10px;
  }

  &.column {
    flex-direction: column;
  }

  .control-bar {
    display: flex;
    margin: 10px 10px 0 10px;
  }
}

.fill {
  flex: 1;
}

.flex-item {
  position: relative;
  flex: 1;
}

@include angular-material-theme($app-theme);
